import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Button, Modal, message, Input, Select, Upload, Popconfirm } from 'antd';
import './index.less';
import { UploadOutlined } from '@ant-design/icons';
// import type { UploadProps } from 'antd';
import { GetRequireList, SelectIsUse } from '../../api/require';
import { AddRecurrent, DelRecurrent, GetBeforeRecurrentList, GetRecurrentList, GetSubjectList, UploadPhoto } from '../../api/recurrent';

// import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

// const baseUrl = "http://192.168.0.102:10002";
const baseUrl = "https://statistics.topcharts.cn/api";

function index() {
    const text = '删除确认'
    const columns = [
        {
            title: 'Id',
            dataIndex: 'recordId',
            key: 'recordId',
            width: 80,
        },
        {
            title: '图表名称',
            dataIndex: 'paperChartName',
            key: 'paperChartName',
            width: 380,
        },
        {
            title: '图表类型',
            dataIndex: 'chartName',
            key: 'chartName',
            // width: 120,
        },
        {
            title: '来源期刊',
            dataIndex: 'chartName',
            key: 'chartName',
            // width: 120,
        },
        {
            title: '学科',
            dataIndex: 'subjectName',
            key: 'subjectName',
            // width: 120,
        },
        {
            title: '操作',
            key: 'action',
            dataIndex: 'action',
            render: (item: any, record: any) => (
                <span>

                    <Popconfirm
                        placement="topRight"
                        title={text}
                        onConfirm={() =>delITem(item, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            // onClick={() => beforeDelITem(item, record)}
                            type="primary"
                        >
                            删除
                        </Button>
                    </Popconfirm>

                </span>
            ),
        },
    ];
    const confirm = () => {
        message.info('Clicked on Yes.');
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [chartName, setChartName] = useState('');
    const [tableData, setTableData] = useState<any>([]);
    const [showPage, setShowPage] = useState("list")

    const [selectChart, setSelectChart] = useState("");
    const [selectSubject, setSelectSubject] = useState("");
    const [chartSource, setChartSource] = useState("");

    const [selectChartOp, setSelectChartOp] = useState<any>([]);
    const [selectSubjectOp, setSelectSubjectOp] = useState<any>([]);

    const [content, setContent] = useState(""); // 存储 Markdown 文本
    const [uploadImgUrl, setUploadImgUrl] = useState(""); // 存储 Markdown 文本

    useEffect(() => {
        getRequireList();
        getSubjectList();
        getBeforeRecurrentList();
    }, []);

    const getRequireList = async () => {
        try {
            const parmas = {
                keyword: chartName,
                page: 1,
                pageSize: 1000
            };
            const { code, data } = await GetRecurrentList(parmas);
            if (code == 200) {
                setTableData(data)
            }
        } catch (error) {
            message.error('请求失败，请稍后重试');
            console.log('output->', error);
        }
    };
    const getBeforeRecurrentList = async () => {
        try {
            const params = {
                name: ""
            }
            const { code, data } = await GetBeforeRecurrentList(params);
            if (code == 200) {
                console.log('output->', data.chartRecords);
                const _list: any = []
                if (data.chartRecords) {
                    data.chartRecords.forEach((item: any) => {
                        let obj = {
                            label: item.chartName,
                            value: item.recordId,
                        }
                        _list.push(obj)
                    });
                }
                setSelectChartOp(_list)
            }
        } catch (error) {
            message.error('请求失败，请稍后重试');
            console.log('output->', error);
        }
    }
    const getSubjectList = async () => {
        try {
            const { code, data } = await GetSubjectList();
            if (code == 200) {
                const _list: any = []
                if (data) {
                    data.forEach((item: any) => {
                        let obj = {
                            label: item.subjectName,
                            value: item.id,
                        }
                        _list.push(obj)
                    });
                }
                setSelectSubjectOp(_list)
            }
        } catch (error) {
            message.error('请求失败，请稍后重试');
            console.log('output->', error);
        }
    }
    const beforeDelITem = async (v: any, r: any) => {
        console.log('output->', v);
        console.log('output->', r);
        setIsModalOpen(true)
    }

    const delITem = async (v: any, r: any) => {
        // console.log('output->', v);
        // console.log('output->', r);
        const params = {
            recordId: r.recordId
        }
        const { code, data, msg } = await DelRecurrent(params)
        if (code == 200) {
            message.success(msg)
            getRequireList()
        }
    }
    // 编辑器内容变化时的回调
    const handleEditorChange = ({ text }: any) => {
        setContent(text);
    };

    const handleImageUpload = async (file: File, callback: (url: string) => void) => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            callback(uploadImgUrl); // 回传图片URL到编辑器
        } catch (error) {
            console.error('Upload error:', error);
            // 可以在这里显示错误提示
            callback(''); // 上传失败传空字符串
        }
    };
    const props: any = {
        name: 'file',
        action: `${baseUrl}/upload/photoToOss`,
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        // },
        onChange(info: any) {
            console.log('output->', info);
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                addImgUrl(info.file.response.data)

                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    const addImgUrl = (v: any) => {
        const url = `${content}
![](${v})`
        setContent(url)
    }
    // 新增论文复现
    const addRecurrent = async () => {
        const params = {
            recordId: selectChart,
            subjectId: selectSubject,
            paperOrigin: chartSource,
            paperChartInstr: content,
        }
        const { code, data, msg } = await AddRecurrent(params)
        if (code == 200) {
            message.success(msg)
            reset()
            getRequireList()
            setShowPage('list')
        }
    }
    // 重置
    const reset = () => {
        setSelectChart("")
        setSelectSubject("")
        setChartSource("")
        setContent("")
    }
    return (
        <>
            {showPage == "list" && <div className="gutter-example button-demo">
                <Row gutter={16}>
                    <Col md={24}>
                        <Card title="论文复现" bordered={false}>
                            <div
                                className="listBox"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '10px',
                                }}
                            >
                                <div className='topLeft'>
                                    <span style={{ marginLeft: '20px' }}>图表名称：</span>
                                    <Input
                                        allowClear
                                        style={{ width: '180px' }}
                                        placeholder="请输入图表名称"
                                        onChange={(e) => setChartName(e.target.value)}
                                    />
                                    <Button style={{ marginLeft: '20px' }} onClick={() => getRequireList()}>
                                        查询
                                    </Button>
                                </div>
                                <Button onClick={() => setShowPage('add')}>新增</Button>
                            </div>
                            <Table columns={columns} dataSource={tableData} />
                        </Card>
                    </Col>
                </Row>
                {/* <Modal title="删除确认" open={isModalOpen} onOk={delITem} onCancel={() => setIsModalOpen(false)}>
                    确定删除？
                </Modal> */}
            </div>}
            {showPage == "add" && <div className='gutter-example button-demo'>
                <Row gutter={16}>
                    <Col md={24}>
                        <Card title="新增论文复现" bordered={false}>
                            <Button style={{ marginBottom: '20px' }} onClick={() => setShowPage('list')}>返回</Button>
                            <div className='addTop'>
                                <span>选择图表</span>
                                <Select
                                    defaultValue="lucy"
                                    style={{ width: 300 }}
                                    value={selectChart}
                                    onChange={setSelectChart}
                                    options={selectChartOp}
                                />
                                <span>选择学科</span>
                                <Select
                                    defaultValue="lucy"
                                    style={{ width: 240 }}
                                    value={selectSubject}
                                    onChange={setSelectSubject}
                                    options={selectSubjectOp}
                                />
                                <span>输入图表来源</span>
                                <Input
                                    allowClear
                                    style={{ width: '200px' }}
                                    placeholder="请输入图表来源"
                                    onChange={(e) => setChartSource(e.target.value)}
                                />
                                <Upload {...props}>
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                                <Button onClick={addRecurrent}>新增论文复现</Button>
                            </div>
                            <div className='mdBox'>
                                <MdEditor
                                    style={{ height: '600px' }}
                                    value={content}
                                    onChange={handleEditorChange}
                                    onImageUpload={handleImageUpload}
                                    renderHTML={(text) => <div>{text}</div>} // 预览渲染逻辑（需自行实现或结合 react-markdown）
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>}
        </>
    );
}


export default index
