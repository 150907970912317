import React, { useEffect, useState } from 'react'
import { Card, Col, Pagination, Radio, Row, DatePicker, Input, Button, Image, Spin, Table } from 'antd';
const { RangePicker } = DatePicker;
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { useHistory } from 'react-router-dom';
import { ExportAIRecordList, GetAIRecordList } from '../../api/data';
import './AiModify.less';
import { downloadFile } from '../../utils/common';

let pageSize = 10;
function AiModify() {
    const history = useHistory();
    const columns = [
        {
            title: '手机号',
            dataIndex: 'phonenumber',
            key: 'phonenumber',
            render: (text: any) => <span style={{ cursor: "pointer" }} onClick={() => toSavePage(text)}>{text}</span>,
            width: 60,
        },
        {
            title: '发起对话时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
            width: 80
        },
        {
            title: '用户发送内容',
            dataIndex: 'userMsg',
            key: 'userMsg',
            ellipsis: true,
            width: 200
        },
        {
            title: 'AI返回内容',
            dataIndex: 'aiResponse',
            key: 'aiResponse',
            ellipsis: true,
            width: 200
        },
        {
            title: '系统发送内容',
            dataIndex: 'message',
            key: 'message',
            ellipsis: true,
            width: 200,
            render: (text: any) => <span className='message-col' >{text}</span>,
        },
        // {
        //     title: '操作',
        //     key: 'action',
        //     dataIndex: 'action',
        //     render: (item: any, record: any) => (
        //         <span>
        //             <Button onClick={() => onClickEditDialog(record)}>编辑</Button>
        //             <span className="ant-divider" />
        //             <Button type="primary" danger>
        //                 删除
        //             </Button>
        //             <span className="ant-divider" />
        //         </span>
        //     ),
        // },
    ];
    const [tableData, setTableData] = useState<any>([]);
    const [recordList, setRecordList] = useState([])
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [pageNum, setPageNum] = useState<any>(1)
    const [listTotal, setListTotal] = useState<any>(0)

    useEffect(() => {
        getAIRecordList()
        pageSize = 10;
        return () => {
            pageSize = 10;
        }
    }, [])

    const toSavePage = (val: string) => {
        history.push(`/app/dataManage/saveManage?phoneNumber=${val}`);
    }

    // 获取AI对话记录列表
    const getAIRecordList = async (pageNum: any = 1) => {
        console.log('output->', pageSize);
        const parmas = {
            startTime,
            endTime,
            phoneNumber,
            pageNum,
            pageSize: pageSize
        }
        const { code, data } = await GetAIRecordList(parmas);
        if (code == 200) {
            handleList(data)
        }
    }

    // 导出AI对话记录列表
    const exportAIRecordList = async (pageNum: any = 1) => {
        console.log('output->', pageSize);
        const parmas = {
            startTime,
            endTime,
            phoneNumber
        }
        const res = await ExportAIRecordList(parmas);
        let fileName: any = `AI改图记录数据`;
        downloadFile(res, fileName, '.xlsx');
    }
    const handleList = (data: any) => {
        const { curentTotal, total, list } = data
        const _total = curentTotal < total ? curentTotal : total
        setListTotal(_total)
        setTableData(list)
    }

    const handleDateSelect = (date: any, dateString: any) => {
        setStartTime(dateString[0]);
        setEndTime(dateString[1]);
    };
    const onClickQuery = () => {
        getAIRecordList(1)
    }
    const changePage = (page: any, pageSize: any) => {
        setPageNum(page)
        getAIRecordList(page)
    }
    const onShowSizeChange = (current: any, _pageSize: any) => {
        pageSize = _pageSize
        // console.log('output->', _pageSize);
    }
    return (
        <div className="gutter-example aiModify">
            <Row gutter={16}>
                <Col md={24}>
                    <Card title="制图详情" bordered={false}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '20px',
                                position: 'relative',
                            }}
                        >
                            <div style={{ marginLeft: '40px' }}>
                                <span style={{ marginRight: '10px' }}>日期：</span>
                                <RangePicker
                                    onChange={handleDateSelect}
                                    locale={locale}
                                    ranges={{
                                        今天: [moment(), moment()],
                                        本月: [moment().startOf('month'), moment().endOf('month')],
                                        本周: [moment().startOf('week'), moment().endOf('week')],
                                    }}
                                />
                            </div>
                            <span style={{ marginLeft: '20px' }}>手机号：</span>
                            <Input
                                allowClear
                                style={{ width: '160px' }}
                                placeholder="请输入手机号"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            {/* <Button
                        style={{ marginLeft: '20px' }}
                        onClick={resetCondi}
                    >
                        重置
                    </Button> */}
                            <Button
                                style={{ marginLeft: '20px' }}
                                onClick={() => onClickQuery()}
                                type="primary"
                            >
                                查询
                            </Button>
                            <Button 
                            style={{ marginLeft: '20px', position: 'absolute', right: 0 }}
                            onClick={() => exportAIRecordList()}
                            >导出</Button>
                        </div>
                        <div className='list-box'>
                            <Table
                                columns={columns}
                                dataSource={tableData}
                                pagination={false}
                            />
                        </div>

                        <Pagination
                            current={pageNum}
                            defaultCurrent={1}
                            total={listTotal}
                            onChange={changePage}
                            showSizeChanger={true}
                            showQuickJumper
                            pageSizeOptions={['10', '20', '50', '100']}
                            defaultPageSize={10}
                            onShowSizeChange={onShowSizeChange}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}


export default AiModify
