import React, { useState, useEffect, useRef, createRef } from 'react';
import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import { Row, Col, Card, Table, Button, Modal, message, Input, Select, Image, DatePicker } from 'antd';
import {
    GetSavenInfos,
    GetUserSaveImgList,
    DownSavenInfos,
    GetUserExportImgList,
    GetUserScreenImgList,
    GetUserSaveImgListNew,
} from '../../api/data';
import { downloadFile } from '../../utils/common';
import locale from 'antd/es/date-picker/locale/zh_CN';
const { RangePicker } = DatePicker;
import moment from 'moment';

const SaveManage = (props: any) => {
    const columns = [
        {
            title: '注册时间',
            dataIndex: 'formattedCreateTime',
            key: 'formattedCreateTime',
        },
        {
            title: '手机号',
            dataIndex: 'phonenumber',
            key: 'phonenumber',
        },
        {
            title: 'VIP',
            dataIndex: 'isVips',
            key: 'isVips',
        },
        {
            title: '导出文件数',
            dataIndex: 'exportCount',
            key: 'exportCount',
            render: (text: any, record: any) => (
                <a onClick={() => showImgListModal(record, 0)}>{text}</a>
            ),
        },
        {
            title: '保存文件数',
            dataIndex: 'chartConfigCount',
            key: 'chartConfigCount',
            render: (text: any, record: any) => (
                <a onClick={() => showImgListModal(record, 1)}>{text}</a>
            ),
        },
        {
            title: '新版保存文件数',
            dataIndex: 'chartRecordCount',
            key: 'chartRecordCount',
            render: (text: any, record: any) => (
                <a onClick={() => showImgListModal(record, 2)}>{text}</a>
            ),
        },
        {
            title: '大屏保存数',
            dataIndex: 'chartViewsCount',
            key: 'chartViewsCount',
            render: (text: any, record: any) => (
                <a onClick={() => showImgListModal(record, 3)}>{text}</a>
            ),
        },
        {
            title: '保存席位',
            dataIndex: 'seatCount',
            key: 'seatCount',
        },

        // {
        //     title: '操作',
        //     key: 'action',
        //     dataIndex: 'action',
        //     render: (item: any, record: any) => (
        //         <span>
        //             <Button onClick={() => onClickEditDialog(record)}>编辑</Button>
        //             <span className="ant-divider" />
        //             <Button type="primary" danger>
        //                 删除
        //             </Button>
        //             <span className="ant-divider" />
        //         </span>
        //     ),
        // },
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [exportCountOver, setExportCountOver] = useState('');
    const [saveCountOver, setSaveCountOver] = useState('');
    const [saveNewCountOver, setSaveNewCountOver] = useState('');
    const [screenCountOver, setScreenCountOver] = useState('');
    const [tableData, setTableData] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imgeList, setImgeList] = useState([] as any[]);
    const [cPhoneNumber, setCPhoneNumber] = useState('');
    const [modalTitle, setModalTitle] = useState('保存文件图片列表');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    let phoneNumberRef: any = useRef<Input>(null);
    let exportCountRef: any = useRef<Input>(null);
    let saveCountRef: any = useRef<Input>(null);
    let saveNewCountRef: any = useRef<Input>(null);
    let saveScreenCountRef: any = useRef<Input>(null);

    useEffect(() => {
        changePhoneFromProps()
        getSavenInfos();
    }, []);
    let _phoneNumber: any;
    const changePhoneFromProps = () => {
        if (props.query.phoneNumber) {
            _phoneNumber = JSON.parse(JSON.stringify(props.query.phoneNumber))
            setPhoneNumber(_phoneNumber);
            phoneNumberRef.current.setState({ value: _phoneNumber });
        }
    }
    const getSavenInfos = async () => {
        try {
            const parmas = {
                startTime,
                endTime,
                phoneNumber: _phoneNumber ? _phoneNumber : phoneNumber,
                source: '',
                knowChannel: '',
                exportCount: parseInt(exportCountOver),
                chartConfigCount: parseInt(saveCountOver),
                chartRecordCount: parseInt(saveNewCountOver),
                chartViewsCount: parseInt(screenCountOver),
            };
            const { code, data } = await GetSavenInfos(parmas);
            if (code == 200) {
                setTableData(data);
            }
        } catch (error) {
            console.log('output->', error);
        }
    };

    const checkboxChage = (newSelectedRowKeys: any, newSelectedRows: any) => {
        setSelectedRowKeys(newSelectedRowKeys);
        console.log(newSelectedRows[0]);
    };

    const imgListMap: any = {
        0: '导出文件图片列表',
        1: '保存文件图片列表',
        2: '保存文件图片列表（新版）',
        3: '大屏保存图片列表',
    }
    const showImgListModal = async (record: any, type: number = 0) => {
        // console.log('output->', record);
        setModalTitle(imgListMap[type]);
        setCPhoneNumber(record.phonenumber);
        if (type === 0) getUserExportImgList(record);
        else if (type === 1) getUserSaveImgList(record);
        else if (type === 2) getUserSaveImgListNew(record);
        else if (type === 3) getUserScreenImgList(record);
        setIsModalOpen(true);
    };
    const getUserSaveImgList = async (record: any) => {
        try {
            const parmas = {
                startTime,
                endTime,
                userId: record.userId
            }
            const { code, data } = await GetUserSaveImgList(parmas);
            if (code === 200) {
                handleSaveImgList(data);
                // setImgeList(data)
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    const getUserSaveImgListNew = async (record: any) => {
        try {
            const parmas = {
                startTime,
                endTime,
                userId: record.userId
            }
            const { code, data } = await GetUserSaveImgListNew(parmas);
            if (code === 200) {
                handleSaveImgList(data);
                // setImgeList(data)
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    const getUserExportImgList = async (record: any) => {
        try {
            const parmas = {
                startTime,
                endTime,
                userId: record.userId
            }
            const { code, data } = await GetUserExportImgList(parmas);
            if (code === 200) {
                handleSaveImgList(data);
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    const getUserScreenImgList = async (record: any) => {
        try {
            const parmas = {
                startTime,
                endTime,
                userId: record.userId
            }
            const { code, data } = await GetUserScreenImgList(parmas);
            if (code === 200) {
                handleSaveImgList(data);
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    const closeFileListModal = () => {
        setImgeList([]);
        setIsModalOpen(false);
    };
    const handleSaveImgList = (data: any) => {
        let _data: any[] = [];
        let pattern = /^https/
        data.forEach((item: { imageUrl: any }) => {
            if (item.imageUrl) {
                let url = pattern.test(item.imageUrl) ? item.imageUrl : `https://www.topcharts.cn${item.imageUrl}`;
                let obj = {
                    imageUrl: url,
                };
                _data.push(obj);
            }
        });
        // console.log('output->', _data);
        setImgeList(_data);
        // console.log('output->', imgeList);
    };

    const handleDateSelect = (date: any, dateString: any) => {
        // console.log('output->',dateString);
        setStartTime(dateString[0]);
        setEndTime(dateString[1]);
        // setAction(val)
    };

    // 导出数据
    const exportStatsData = async () => {
        const params = {
            startTime,
            endTime,
            phoneNumber: _phoneNumber ? _phoneNumber : phoneNumber,
            source: '',
            knowChannel: '',
            exportCount: parseInt(exportCountOver),
            chartConfigCount: parseInt(saveCountOver),
            chartRecordCount: parseInt(saveNewCountOver),
            chartViewsCount: parseInt(screenCountOver),
        };
        const res = await DownSavenInfos(params);
        let fileName: any = `用户保存数据`;
        downloadFile(res, fileName, '.xlsx');
    };

    const resetCondi = () => {
        try {
            _phoneNumber = "";
            phoneNumberRef.current.setState({ value: "" });
            exportCountRef.current.setState({ value: "" });
            saveCountRef.current.setState({ value: "" });
            saveNewCountRef.current.setState({ value: "" });
            saveScreenCountRef.current.setState({ value: "" });
            setPhoneNumber("");
            setExportCountOver("");
            setSaveCountOver("");
            setSaveNewCountOver("");
            setScreenCountOver("");
        } catch (error) {
            console.log('error', error);
        }

        // getSavenInfos();
    }
    return (
        <div className="gutter-example button-demo">
            {/* <BreadcrumbCustom breads={['主题管理']} /> */}
            <Row gutter={16}>
                <Col md={24}>
                    <Card title="保存管理" bordered={false}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            <div>
                                <span style={{ marginRight: '10px' }}>日期：</span>
                                <RangePicker
                                    onChange={handleDateSelect}
                                    locale={locale}
                                    ranges={{
                                        今天: [moment(), moment()],
                                        本月: [moment().startOf('month'), moment().endOf('month')],
                                        本周: [moment().startOf('week'), moment().endOf('week')],
                                    }}
                                />
                            </div>
                            <span style={{ marginLeft: '20px' }}>手机号：</span>
                            <Input
                                allowClear
                                style={{ width: '160px' }}
                                placeholder="请输入手机号"
                                ref={phoneNumberRef}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            <span style={{ marginLeft: '20px' }}>导出数大于：</span>
                            <Input
                                allowClear
                                style={{ width: '70px' }}
                                placeholder=""
                                ref={exportCountRef}
                                onChange={(e) => setExportCountOver(e.target.value)}
                            />
                            <span style={{ marginLeft: '20px' }}>保存数大于：</span>
                            <Input
                                allowClear
                                style={{ width: '70px' }}
                                placeholder=""
                                ref={saveCountRef}
                                onChange={(e) => setSaveCountOver(e.target.value)}
                            />
                            <span style={{ marginLeft: '20px' }}>新版保存数大于：</span>
                            <Input
                                allowClear
                                style={{ width: '70px' }}
                                placeholder=""
                                ref={saveNewCountRef}
                                onChange={(e) => setSaveNewCountOver(e.target.value)}
                            />
                            <span style={{ marginLeft: '20px' }}>大屏数大于：</span>
                            <Input
                                allowClear
                                style={{ width: '70px' }}
                                placeholder=""
                                ref={saveScreenCountRef}
                                onChange={(e) => setScreenCountOver(e.target.value)}
                            />
                            <Button
                                style={{ marginLeft: '20px' }}
                                onClick={resetCondi}
                            >
                                重置
                            </Button>
                            <Button
                                style={{ marginLeft: '20px' }}
                                onClick={() => getSavenInfos()}
                                type="primary"
                            >
                                查询
                            </Button>

                            <Button
                                style={{ position: 'absolute', right: '15px' }}
                                type="primary"
                                onClick={() => exportStatsData()}
                            >
                                导出
                            </Button>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            rowSelection={{
                                type: 'checkbox',
                                onChange: checkboxChage,
                                selectedRowKeys,
                            }}
                        />
                    </Card>
                </Col>
            </Row>
            <Modal
                style={{ marginTop: '0vh', width: '800px' }}
                bodyStyle={{ maxHeight: '600px', overflowY: 'auto' }}
                width={800}
                title={`${modalTitle} ${cPhoneNumber}`}
                visible={isModalOpen}
                onCancel={() => closeFileListModal()}
                onOk={() => closeFileListModal()}
            >
                <div className="gutter-example button-demo">
                    {imgeList.map((item) => {
                        return <Image width={300} style={{ margin: '10px' }} src={item.imageUrl} />;
                    })}
                </div>
            </Modal>
        </div>
    );
};

export default SaveManage;
