import React, { useState, useEffect } from 'react';
import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import { Row, Col, Card, Table, Button, Modal, message, Input, Select } from 'antd';
import { GetOrdernInfos, ExportOrderInfos } from '../../api/order';
import { downloadFile } from '../../utils/common';
import { useHistory } from 'react-router-dom';
// import value from './themeTest';

const { TextArea } = Input;
const ThemeManage = () => {
    const history = useHistory();
    const columns = [
        {
            title: '手机号',
            dataIndex: 'phonenumber',
            key: 'phonenumber',
            render: (text: any) => <span style={{ cursor: "pointer" }} onClick={() => toSavePage(text)}>{text}</span>,
        },
        {
            title: '订单号',
            dataIndex: 'orderNum',
            key: 'orderNum',
        },
        {
            title: '订单详情',
            dataIndex: 'orderDetails',
            key: 'orderDetails',
            render: (text: any) => <span>{text}</span>,
        },
        {
            title: '订单时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '金额',
            dataIndex: 'totalPriceInYuan',
            key: 'totalPriceInYuan',
        },
        {
            title: '发票',
            dataIndex: 'invoiceStatus',
            key: 'invoiceStatus',
        },
        // {
        //     title: '操作',
        //     key: 'action',
        //     dataIndex: 'action',
        //     render: (item: any, record: any) => (
        //         <span>
        //             <Button onClick={() => onClickEditDialog(record)}>编辑</Button>
        //             <span className="ant-divider" />
        //             <Button type="primary" danger>
        //                 删除
        //             </Button>
        //             <span className="ant-divider" />
        //         </span>
        //     ),
        // },
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [themeItem, setThemeItem] = useState({ name: '', type: null, colorValue: '' });
    const [addDialogTitle, setAddDialogTitle] = useState('新增主题配色方案');
    const [tableData, setTableData] = useState<any>([]);
    useEffect(() => {
        getOrdernInfos();
    }, []);
    const getOrdernInfos = async () => {
        try {
            const { code, data } = await GetOrdernInfos();
            if (code == 200) {
                setTableData(data);
            }
        } catch (error) {
            console.log('output->', error);
        }
    };
    const checkboxChage = (newSelectedRowKeys: any, newSelectedRows: any) => {
        setSelectedRowKeys(newSelectedRowKeys);
        console.log(newSelectedRows[0]);
    };

    const exportOrderInfos = async () => {
        // const { startTime, endTime } = this.state;
        const params = {
        };
        const res = await ExportOrderInfos(params);
        // let fileName:any = `${startTime}~${endTime}用户订单数据`;
        let fileName: any = `用户订单数据`;
        downloadFile(res, fileName, '.xlsx');
    }
    const toSavePage = (val: string) => {
        history.push(`/app/dataManage/saveManage?phoneNumber=${val}`);
    }

    const submit = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="gutter-example button-demo">
            {/* <BreadcrumbCustom breads={['主题管理']} /> */}
            <Row gutter={16}>
                <Col md={24}>
                    <Card title="消费订单"
                        extra={
                            <Button
                                type="primary"
                                onClick={() =>
                                    exportOrderInfos()
                                }
                            >
                                导出
                            </Button>
                        }
                        bordered={false}>
                        {/* <div className="theme-top-con">
                            <Button type="primary" onClick={onClickAddDialog}>
                                新增
                            </Button>
                            <Button type="primary" danger>
                                删除
                            </Button>
                        </div> */}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            {/* <div>
                                            <span style={{ marginRight: '10px' }}>日期：</span>
                                            <RangePicker
                                                onChange={this.handleDateSelect}
                                                locale={locale}
                                                ranges={{
                                                    今天: [moment(), moment()],
                                                    本月: [
                                                        moment().startOf('month'),
                                                        moment().endOf('month'),
                                                    ],
                                                    本周: [
                                                        moment().startOf('week'),
                                                        moment().endOf('week'),
                                                    ],
                                                }}
                                            />
                                        </div> */}
                            {/* <span style={{ marginLeft: '20px' }}>手机号：</span>
                                        <Input
                                            allowClear
                                            style={{ width: '180px' }}
                                            placeholder="请输入手机号"
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                        <Button
                                            style={{ marginLeft: '20px' }}
                                            onClick={() => searchAllByDate()}
                                        >
                                            查询
                                        </Button> */}
                        </div>
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            rowSelection={{
                                type: 'checkbox',
                                onChange: checkboxChage,
                                selectedRowKeys,
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ThemeManage;
