/**
 * 用户数据统计
 */
import React, { useState } from 'react';
import {
    Row,
    Col,
    Card,
    Timeline,
    Table,
    Button,
    DatePicker,
    Modal,
    Select,
    Tag,
    message,
    Input,
    Popconfirm,
} from 'antd';
import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import {
    GetChartUseData,
    GetUserInfoData,
    ExportUserInfoData,
    AddUserToVip,
    GetUserSource,
    DelUser,
} from '../../api/stats';
import { downloadFile } from '../../utils/common';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
}
const { RangePicker } = DatePicker;

class Dashboard extends React.Component {
    state = {
        selectOptions: [
            {
                label: '',
                value: '',
            },
        ],
        pagination: {
            total: 0,//数据的总条数
            defaultCurrent: 1,//默认当前的页数
            defaultPageSize: 10,//默认每页的条数
            onChange: (page: any, pageSize: any) => {
                this.getUserInfoData(page, pageSize)
            }
        },
        columns: [],
        transmitColumns: [
            {
                title: '类型',
                dataIndex: 'type',
            },
            {
                title: '转发点击',
                dataIndex: 'click',
            },
        ],
        userColumns: [
            // {
            //     title: '登录名称',
            //     dataIndex: 'userName',
            //     width: 140,
            // },
            {
                title: '用户名称',
                dataIndex: 'nickName',
                width: 140,
                render: (item: any, record: any) => (
                    <span style={{ color: record.isVip == '是' ? '#CD5C5C' : '#000' }}>{item}</span>
                ),
            },
            {
                title: '手机号码',
                dataIndex: 'phonenumber',
                width: 140,
                render: (item: any, record: any) => (
                    <span style={{ color: record.isVip == '是' ? '#CD5C5C' : '#000' }}>{item}</span>
                ),
            },
            {
                title: 'Vip',
                dataIndex: 'isVip',
                width: 60,
            },
            {
                title: 'Vip到期时间',
                dataIndex: 'expirationTime',
                width: 120,
            },
            {
                title: '付费用户',
                dataIndex: 'hasPaidInfo',
                width: 60,
            },
            {
                title: '智点余额',
                dataIndex: 'totalCoins',
                width: 60,
            },
            {
                title: '用户来源',
                dataIndex: 'regChannelName',
                width: 100,
            },
            {
                title: '注册方式',
                dataIndex: 'platformSecond',
                width: 100,
            },
            {
                title: '注册时间',
                dataIndex: 'createTime',
                width: 120,
            },
            {
                title: '最后登录时间',
                dataIndex: 'loginDate',
                width: 120,
            },
            {
                title: '月登录次数',
                dataIndex: 'monthlyLoginCount',
                width: 80,
            },
            {
                title: '总登录次数',
                dataIndex: 'loginCount',
                width: 80,
            },
            {
                title: '完善信息',
                dataIndex: 'hasCompleteInfo',
                width: 80,
            },
            {
                title: '完善来源',
                dataIndex: 'lightSourceName',
                width: 100,
            },
            // {
            //     title: '保存文件数',
            //     dataIndex: 'chartConfigCount',
            //     width: 80,
            // },
            // {
            //     title: '可保存文件数',
            //     dataIndex: 'seatCount',
            //     width: 80,
            // },
            {
                title: '用户性别',
                dataIndex: 'sex',
                width: 60,
            },
            {
                title: '学校',
                dataIndex: 'schoolName',
                width: 180,
            },
            {
                title: '公司',
                dataIndex: 'company',
                width: 180,
            },
            {
                title: '学历',
                dataIndex: 'education',
                width: 80,
            },
            {
                title: '专业',
                dataIndex: 'speciality',
                width: 100,
            },
            {
                title: '需求',
                dataIndex: 'demand',
                width: 280,
            },
            {
                title: '身份/岗位',
                dataIndex: 'post',
                width: 100,
            },
            {
                title: '了解渠道',
                dataIndex: 'knowChannelSecond',
                width: 80,
            },
            // {
            //     title: '其他了解渠道',
            //     dataIndex: 'otherKnowChannel',
            //     width: 80,
            // },
            {
                title: '用户邮箱',
                dataIndex: 'email',
                width: 80,
            },
            {
                title: '关注状态',
                dataIndex: 'isSubscribe',
                width: 80,
            },
            {
                title: 'wx用户id',
                dataIndex: 'openId',
                width: 80,
            },
            {
                title: '操作',
                key: 'action',
                dataIndex: 'action',
                fixed: 'right',
                render: (item: any, record: any) => (
                    <span>
                        <Popconfirm
                            placement="topLeft"
                            title={'确定删除该用户吗？'}
                            onConfirm={() => this.delUser(record)}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Button type="primary" danger>
                                删除
                            </Button>
                            {/* <Button type="primary" danger onClick={() => this.delUser(record)}>删除</Button> */}
                        </Popconfirm>
                    </span>
                ),
            },
        ],
        delUserId: null,
        userSourceOption: [
            // {
            //     value: 1,
            //     label: '正常注册',
            // },
            // {
            //     value: 2,
            //     label: '联通公司',
            // },
            // {
            //     value: 3,
            //     label: '抖音',
            // },
        ],
        saveTableData: [],
        exportTableData: [],
        transmitTableData: [],
        userTableData: [],
        startTime: '',
        endTime: '',
        totalUser: 0,
        newUser: 0,
        userName: '',
        userSource: '',
        knowChannel: '',
        visible: false,
        setIsModalOpen: false,
        selectedList: [],
        selectedRows: [],
        expirationTime: moment().add(1, 'days').format('YYYY-MM-DD HH:MM:SS'),
    };
    fieldNames = {
        label: 'name',
        value: 'source',
        options: 'options',
    };
    // 获取图表使用统计数据
    // async getChartUseData(params: any) {
    //     const { code, data } = await GetChartUseData(params);
    //     if (code === 200) {
    //         return data;
    //     }
    // }
    // 获取表格表头数据(保存/导出)
    getTableColumns(data: any) {
        const newColumns: any = [
            {
                title: '项目名字',
                dataIndex: 'type',
                fixed: 'left',
                width: 70,
            },
        ];
        data.forEach((item: { name: any; configId: any }) => {
            const columnsItem = {
                title: item.name,
                dataIndex: `chart${item.configId}`,
                width: 100,
            };
            newColumns.push(columnsItem);
        });
        this.setState({
            columns: newColumns,
        });
    }
    delUser = async (val: any) => {
        console.log('output->', val.userId);
        try {
            const _params = {
                userId: val.userId,
            };
            const { code, data, msg } = await DelUser(_params);
            if (code == 200) {
                message.success(msg || '删除成功！');
                this.getUserInfoData();
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    setUserName = (val: any) => {
        this.setState({
            userName: val,
        });
    };
    setUserSource = (val: any) => {
        // console.log('output->', val);
        this.setState({
            userSource: val,
        });
    };
    setKnowChannel = (val: any) => {
        // console.log('output->', val);
        this.setState({
            knowChannel: val,
        });
    };

    // 获取用户信息统计数据
    getUserInfoData = async (pageNum = 1, pageSize = 10) => {
        const params = {
            pageNum,
            pageSize,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            phoneNumber: this.state.userName,
            source: this.state.userSource,
            knowChannel: this.state.knowChannel,
        };
        const { code, data } = await GetUserInfoData(params);
        if (code == 200) {
            this.setState({
                newUser: data?.total,
            });
            this.setState({
                totalUser: data.registerTotal,
            });
            this.setState({
                pagination: { ...this.state.pagination, total: data.total }
            });
            this.setState({
                userTableData: this.handleUserInfoData(data.list),
            });
        }
    };
    handleUserInfoData(data: any) {
        interface mapObject {
            [key: string]: any;
            [key: number]: any;
        }
        const sexMap: mapObject = {
            0: '男',
            1: '女',
            2: '未知',
        };
        const vipMap: mapObject = {
            0: '否',
            1: '是',
        };
        const subscribeMap: mapObject = {
            0: '已关注',
            1: '未关注',
        };
        const educationMap: mapObject = {
            0: '小学',
            1: '初中',
            2: '高中',
            3: '专科',
            4: '本科',
            5: '硕士',
            6: '博士',
        };
        data.forEach(
            (item: {
                sex: string | number;
                isSubscribe: string | number;
                education: string | number;
                isVip: string | number;
                key: string | number;
                userId: string | number;
            }) => {
                item.sex = sexMap[item.sex];
                item.isSubscribe = subscribeMap[item.isSubscribe];
                item.education = educationMap[item.education];
                item.isVip = vipMap[item.isVip];
                item.key = item.userId;
            }
        );
        return data;
    }
    // 导出用户信息统计数据
    async exportUserInfoData() {
        const { startTime, endTime, userName, userSource } = this.state;
        const params = {
            startTime,
            endTime,
            pageNum: 1,
            pageSize: 4000,
            phoneNumber: userName,
            source: userSource,
        };
        const res = await ExportUserInfoData(params);
        const fileName = `${startTime}~${endTime}用户信息统计数据`;
        downloadFile(res, fileName, '.xlsx');
    }
    handleDateSelect = (date: any, dateString: any) => {
        // console.log(dateString);
        this.setState({
            startTime: dateString[0],
            endTime: dateString[1],
        });
    };
    // 搜索所有用户信息（注册总数和用户信息列表）
    searchAllByDate() {
        this.getUserInfoData();
        // this.getAllUserData();
    }

    /**
     * 获取注册用户总数
     */
    getAllUserData = async () => {
        try {
            const { code, data } = await GetUserInfoData({
                phoneNumber: '',
                startTime: '',
                endTime: '',
                source: '',
            });
            if (code) {
                this.setState({
                    totalUser: data.total,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    setSelectionType = (selectedRowKeys: any, selectedRows: any) => {
        let list: { label: any; value: any }[] = [];
        let _selectedList: any[] = [];
        selectedRows.forEach((item: { nickName: any; userId: any }) => {
            list.push({
                label: item.nickName,
                value: item.userId,
            });
            _selectedList.push(item.userId);
        });
        this.setState({
            selectOptions: list,
            selectedList: _selectedList,
            selectedRows: selectedRowKeys,
        });
    };
    handleOk = () => { };

    vipDateChange = (date: any, dateString: any) => {
        this.setState({
            expirationTime: dateString,
        });
    };
    // 批量新增vip用户
    submit = async () => {
        const { expirationTime, selectedList } = this.state;
        const params = {
            expirationTime,
            isVip: 1,
            userIds: selectedList,
        };
        console.log(params);
        try {
            const { code, msg } = await AddUserToVip(params);
            if (code == 200) {
                message.success(msg);
                this.setState({
                    visible: false,
                    selectedRows: [],
                });
                this.getUserInfoData();
            }
        } catch (error) {
            message.error(error);
        }
    };

    getUserSource = async () => {
        let { code, data } = await GetUserSource();
        if (code == 200) {
            let list: { value: string; label: string }[] = [];
            data.forEach((item: { id: string; name: string }) => {
                let obj = {
                    value: item.id,
                    label: item.name,
                };
                list.push(obj);
            });
            console.log(list);
            this.setState({
                userSourceOption: list,
            });
        }
    };

    async componentDidMount() {
        this.getUserSource();
        this.searchAllByDate();
    }

    render() {
        return (
            <div className="gutter-example button-demo">
                {/* <BreadcrumbCustom /> */}
                <Row gutter={16}>
                    <Col className="gutter-row" md={24}>
                        <div className="gutter-box">
                            <Card
                                title="用户信息统计"
                                extra={
                                    <div>
                                        <Button
                                            type="dashed"
                                            onClick={() =>
                                                this.setState({
                                                    visible: true,
                                                })
                                            }
                                        >
                                            修改VIP到期时间
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={() => this.exportUserInfoData()}
                                        >
                                            导出
                                        </Button>
                                    </div>
                                }
                                bordered={false}
                            >
                                <Col
                                    className="gutter-row"
                                    md={24}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            margin: '0 0 20px',
                                        }}
                                    >
                                        <div style={{ marginRight: '20px' }}>
                                            注册用户总数:
                                            <span
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    color: '#6E01FD',
                                                    marginLeft: '10px',
                                                }}
                                            >
                                                {this.state.totalUser}
                                            </span>
                                        </div>
                                        <div>
                                            本次时间段内新增用户数:
                                            <span
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    color: '#6E01FD',
                                                    marginLeft: '10px',
                                                }}
                                            >
                                                {this.state.newUser}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div>
                                            <span style={{ marginRight: '10px' }}>日期：</span>
                                            <RangePicker
                                                onChange={this.handleDateSelect}
                                                locale={locale}
                                                ranges={{
                                                    今天: [moment(), moment()],
                                                    本月: [
                                                        moment().startOf('month'),
                                                        moment().endOf('month'),
                                                    ],
                                                    本周: [
                                                        moment().startOf('week'),
                                                        moment().endOf('week'),
                                                    ],
                                                }}
                                            />
                                        </div>
                                        <span style={{ marginLeft: '20px' }}>用户来源：</span>
                                        <Select
                                            allowClear
                                            style={{ width: '150px' }}
                                            placeholder="选择用户来源"
                                            onChange={this.setUserSource}
                                            options={this.state.userSourceOption}
                                        />
                                        <span style={{ marginLeft: '20px' }}>了解渠道：</span>
                                        <Select
                                            allowClear
                                            style={{ width: '150px' }}
                                            placeholder="选择了解渠道"
                                            onChange={this.setKnowChannel}
                                            options={this.state.userSourceOption}
                                        />
                                        <span style={{ marginLeft: '20px' }}>手机号：</span>
                                        <Input
                                            allowClear
                                            style={{ width: '180px' }}
                                            placeholder="请输入手机号"
                                            onChange={(e) => this.setUserName(e.target.value)}
                                        />
                                        <Button
                                            style={{ marginLeft: '20px' }}
                                            onClick={() => this.searchAllByDate()}
                                        >
                                            查询
                                        </Button>
                                    </div>
                                </Col>

                                <Table
                                    tableLayout="auto"
                                    bordered
                                    scroll={{ x: 1400 }}
                                    rowSelection={{
                                        type: 'checkbox',
                                        onChange: this.setSelectionType,
                                        selectedRowKeys: this.state.selectedRows,
                                    }}
                                    columns={this.state.userColumns as ColumnsType<DataType>}
                                    dataSource={this.state.userTableData}
                                    pagination={this.state.pagination}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
                {/* 修改vip弹窗 */}
                <Modal
                    style={{ marginTop: '20vh' }}
                    title="修改VIP到期时间"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={() =>
                        this.setState({
                            visible: false,
                        })
                    }
                    footer={[
                        <div key="footer">
                            <Button
                                key="back"
                                size="large"
                                onClick={() =>
                                    this.setState({
                                        visible: false,
                                    })
                                }
                            >
                                取消
                            </Button>
                            ,
                            <Button key="submit" type="primary" size="large" onClick={this.submit}>
                                确定
                            </Button>
                        </div>,
                    ]}
                >
                    <p>
                        <span style={{ marginRight: '10px' }}>已选用户：</span>
                        {this.state.selectOptions.map((item) => {
                            return (
                                <Tag
                                    color="magenta"
                                    key={item.value}
                                    style={{ marginBottom: '5px' }}
                                >
                                    {item.label}
                                </Tag>
                            );
                        })}
                        {/* <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '80%' }}
                        placeholder="请选择用户"
                        defaultValue={this.state.selectedList}
                        onChange={this.handleSelectChange}
                        options={this.state.selectOptions}
                    /> */}
                    </p>
                    <p>
                        <span style={{ marginRight: '10px' }}>请选择VIP到期时间：</span>
                        <DatePicker
                            locale={locale}
                            onChange={this.vipDateChange}
                            // format={'YYYY-MM-DD HH:MM:SS'}
                            format={'yyyy-MM-DD HH:mm:ss'}
                            defaultValue={moment().add(1, 'days')}
                        />
                    </p>
                </Modal>
                {/* <Modal 
                     style={{ marginTop: '20vh' }}
                     title="修改VIP到期时间"
                     visible={this.state.visible}
                     onOk={this.handleOk}
                     onCancel={() =>
                         this.setState({
                             visible: false,
                         })
                     }
                     footer={[
                         <div key="footer">
                             <Button
                                 key="back"
                                 size="large"
                                 onClick={() =>
                                     this.setState({
                                         visible: false,
                                     })
                                 }
                             >
                                 取消
                             </Button>
                             ,
                             <Button key="submit" type="primary" size="large" onClick={this.delUser}>
                                 确定
                             </Button>
                         </div>,
                     ]}>
                    <p>确定删除该用户吗？</p>
                </Modal> */}
            </div>
        );
    }
}

export default Dashboard;
