import { Card, Col, message, Pagination, Radio, Row, DatePicker, Input, Button, Image, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import "./SaveDetails.less"
import { GetSaveAllImgList, GetExportAllImgList } from '../../api/data';
import { CheckboxGroupProps } from 'antd/es/checkbox/Group';
const { RangePicker } = DatePicker;
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// import useScrollToBottom from '../../hooks/useScrollToBottom';
let pageSize = 18;
let isSearch = false;
const _data = [[{"name":"sheet1","freeze":"A1","styles":[],"merges":[],"rows":{"0":{"cells":{"0":{"text":"X轴/数据名"},"1":{"text":"支出（元）"},"2":{},"3":{}}},"1":{"cells":{"0":{"text":"1月"},"1":{"text":"30840"},"2":{},"3":{}}},"2":{"cells":{"0":{"text":"2月"},"1":{"text":"31920"},"2":{},"3":{}}},"3":{"cells":{"0":{"text":"3月"},"1":{"text":"40580"},"2":{},"3":{}}},"4":{"cells":{"0":{"text":"4月"},"1":{"text":"41110"},"2":{},"3":{}}},"5":{"cells":{"0":{"text":"5月"},"1":{"text":"84930"},"2":{},"3":{}}},"6":{"cells":{"0":{"text":"6月"},"1":{"text":"89420"},"2":{},"3":{}}},"7":{"cells":{"0":{"text":"7月"},"1":{"text":"95240"},"2":{},"3":{}}},"8":{"cells":{"0":{"text":"8月"},"1":{"text":"74920"},"2":{},"3":{}}},"9":{"cells":{"0":{"text":"9月"},"1":{"text":"57520"},"2":{},"3":{}}},"10":{"cells":{"0":{"text":"10月"},"1":{"text":"89420"},"2":{},"3":{}}},"11":{"cells":{"0":{"text":"11月"},"1":{"text":"19020"},"2":{},"3":{}}},"12":{"cells":{"0":{"text":"12月"},"1":{"text":"30000"},"2":{},"3":{}}},"13":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"14":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"15":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"16":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"17":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"18":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"19":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"20":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"21":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"22":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"23":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"24":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"25":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"26":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"27":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"28":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"29":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"30":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"31":{"cells":{"0":{},"1":{},"2":{},"3":{}}},"len":50000},"cols":{"len":500},"validations":[],"autofilter":{}}]]

const SaveDetails = () => {
    const [imgList, setImgList] = useState<any[]>([])
    const [pageNum, setPageNum] = useState<any>(1)
    // const [pageSize, setPageSize] = useState<any>(18)
    const [saveList, setSaveList] = useState<any>([])
    const [listTotal, setListTotal] = useState<any>(0)
    const [exportList, setExportList] = useState<any>([])
    const [exportListTotal, setExportListTotal] = useState<any>(0)
    const [showType, setShowType] = useState<any>('save');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const phoneNumberRef: any = useRef<Input>(null);
    const scrollRef: any = useRef(null);

    let isFristLoad = true;

    const options: any = [
        { label: '保存', value: 'save' },
        { label: '导出', value: 'export' },
    ];
    useEffect(() => {
        getSaveAllImgList()
        // const container = scrollRef.current;
        // if (!container) return;
        // console.log('output->',container);
        // const handleScroll = () => {
        //     console.log('output->', "222");
        //     // // 获取文档的滚动高度
        //     // const scrollHeight = document.documentElement.scrollHeight;
        //     // // 获取当前窗口的滚动位置
        //     // const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        //     // // 获取窗口的可视高度
        //     // const clientHeight = document.documentElement.clientHeight;

        //     // // 判断是否滚动到底部
        //     // if (scrollTop + clientHeight >= scrollHeight) {
        //     //     console.log('滚动到底部了');
        //     // }
        //     const { scrollTop, clientHeight, scrollHeight } = container;
        //     const threshold = 20;

        //     if (scrollTop + clientHeight >= scrollHeight - threshold) {
        //         console.log('滚动到底部了');
        //     }
        // };
        // // 为 window 对象添加滚动事件监听器
        // window.addEventListener('scroll', handleScroll);

        // // 组件卸载时移除事件监听器，防止内存泄漏
        // return () => {
        //     window.removeEventListener('scroll', handleScroll);
        // };
    }, [])

    // 获取保存图片列表
    const getSaveAllImgList = async (pageNum: any = 1) => {
        // console.log('output->', pageSize);
        const parmas = {
            startTime,
            endTime,
            phoneNumber,
            pageNum,
            pageSize: pageSize
        }
        const { code, data } = await GetSaveAllImgList(parmas);
        console.log('output->', imgList);
        if (code == 200) {
            handleList(data)
        }
    }
    // 获取导出图片列表
    const getExportAllImgList = async (pageNum: any = 1) => {
        const parmas = {
            startTime,
            endTime,
            phoneNumber,
            pageNum,
            pageSize: pageSize
        }
        const { code, data } = await GetExportAllImgList(parmas);
        if (code == 200) {
            if(data) {
                handleList(data)
            }
        }
    }
    const handleList = (data: any) => {
        const { curentTotal, total,list } = data
        const _total = curentTotal < total ? curentTotal : total
        setListTotal(_total)
        setImgList(formartData(list))
    }
    const formartData = (data: any) => {
        const regex = /^http/;
        let newList: any = []
        data.forEach((item: any) => {
            if (!regex.test(item.imageUrl)) {
                item.imageUrl = `https://www.topcharts.cn/${item.imageUrl}`
            }
            newList.push(item)
        });
        // console.log('output->', newList);
        return newList
    }
    const judgeImgType = (page: any = pageNum, type: any = showType) => {
        // const _page = page?page: pageNum;
        setImgList([]);
        if (type == 'save') {
            getSaveAllImgList(page)
        } else {
            getExportAllImgList(page)
        }
    }
    const switchType = (e: any) => {
        isFristLoad = true;
        setImgList([]);
        // console.log('output->', imgList);
        // setTimeout(()=>{
        // console.log('output->', imgList);
        setShowType(e.target.value)
        setPageNum(1)
        judgeImgType(pageNum, e.target.value)
        // },1000)

        // console.log('output->', e.target.value);
    }
    const changePage = (page: any, pageSize: any) => {
        judgeImgType(page);
        setPageNum(page)
    }
    const onShowSizeChange = (current: any, _pageSize: any) => {
        pageSize = _pageSize
        // console.log('output->', _pageSize);
        judgeImgType(1);
    }

    const handleDateSelect = (date: any, dateString: any) => {
        setStartTime(dateString[0]);
        setEndTime(dateString[1]);
    };

    // const resetCondi = () => {
    //     setPhoneNumber("");
    //     setStartTime('');
    //     setEndTime('');
    // }

    // const onScrollToBottom = () => {
    //     console.log('滚动到底部了');
    //     // loadPage()
    // };
    // useScrollToBottom(scrollRef, onScrollToBottom);

    // const loadPage = () => {
    //     isFristLoad = false
    //     setPageNum(pageNum + 1)
    //     judgeImgType()
    // }

    const onClickQuery = () => {
        isSearch = true;
        isFristLoad = true;
        // setImgList(() => []);
        setPageNum(1)
        judgeImgType(1)
    }

    // 下载excel数据
    const exportExcelData = (excelData: any) => {
        // exportLoading.value = true
        let resData = getExportData(excelData)
        console.log('output->',resData);
        if( !resData.status ){
            // exportLoading.value = false
            // return toast('数据格式错误')
            message.warning('暂无数据');
            return
        }
        //create workbook
        const workbook = XLSX.utils.book_new()
        for( let rd = 0; rd < resData.data.length; rd++ ){
            const worksheet = XLSX.utils.aoa_to_sheet(resData.data[rd])
            XLSX.utils.book_append_sheet(workbook, worksheet, `Sheet${rd+1}`)
        }
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        function s2ab(s: any) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `图表数据-${new Date().getTime()}.xlsx`);
        // exportLoading.value = false
    }

    const getExportData = (data: any) => {
        let runStatus = true
        if(!data) runStatus = false
        let activeData:any = JSON.parse(data)
        // console.log('output->',dataAmount.value);
        // for (let s = 1; s <= dataAmount.value; s++) {
        //     activeData.push(spreadsheet[s].getData())
        // }
        console.log('output->',activeData);
        let excelData:any = []
        try {
            for( let ex in activeData ){
                let content: any = []
                let sheetData = activeData[ex][0].rows
                for (let r in sheetData) {
                    if (r == 'len') {
                        continue
                    }
                    let rowsData: any = sheetData[r].cells || []
                    let items:any = []
                    for (let r in rowsData) {
                        if(rowsData[r].text) items.push( rowsData[r].text )
                    }
                    content.push(items)
                }
                excelData.push(content)
            }
        } catch (err) {
            runStatus = false
        }
        return { status:runStatus,data:excelData };
    } 

    return (
        <div className="gutter-example details-main">
            <Row gutter={16}>
                <Col md={24}>
                    <Card title="制图详情" bordered={false}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '20px',
                            }}
                        >
                            <Radio.Group
                                onChange={switchType}
                                value={showType}
                                options={options}
                                defaultValue="save"
                                optionType="button"
                                buttonStyle="solid"
                            />
                            <div style={{ marginLeft: '40px' }}>
                                <span style={{ marginRight: '10px' }}>日期：</span>
                                <RangePicker
                                    onChange={handleDateSelect}
                                    locale={locale}
                                    ranges={{
                                        今天: [moment(), moment()],
                                        本月: [moment().startOf('month'), moment().endOf('month')],
                                        本周: [moment().startOf('week'), moment().endOf('week')],
                                    }}
                                />
                            </div>
                            <span style={{ marginLeft: '20px' }}>手机号：</span>
                            <Input
                                allowClear
                                style={{ width: '160px' }}
                                placeholder="请输入手机号"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            {/* <Button
                                style={{ marginLeft: '20px' }}
                                onClick={resetCondi}
                            >
                                重置
                            </Button> */}
                            <Button
                                style={{ marginLeft: '20px' }}
                                onClick={() => onClickQuery()}
                                type="primary"
                            >
                                查询
                            </Button>
                        </div>
                        <div className='list-box'>
                            
                            <div className="list">
                                {imgList.map((item: any, index: any) => (<div className='img-item' key={index}>
                                    <div className='item-top'>
                                        <span className='item-phone' onClick={() => exportExcelData(item.userChartData)}>{item.phonenumber}</span>
                                        <span className='item-time'>{item.createTime}</span>
                                    </div>
                                    <Image src={item.imageUrl} height={170} width={'100%'}/>
                                </div>))}
                            </div>
                            {/* <Spin size="large" /> */}
                        </div>

                        <Pagination
                            current={pageNum}
                            defaultCurrent={1}
                            total={listTotal}
                            onChange={changePage}
                            showSizeChanger={true}
                            showQuickJumper
                            pageSizeOptions={['18', '36', '60', '90']}
                            defaultPageSize={18}
                            onShowSizeChange={onShowSizeChange}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default SaveDetails;
