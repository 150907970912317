import httpAjax from '../utils/httpAjax';

/**
 * 获取复现列表
 */
export const GetRecurrentList = (parmas: any) => {
    return httpAjax({
        url: `/chart/paper`,
        method: 'post',
        data: parmas,
    });
};
/**
 * 获取论文复现未转移列表
 */
export const GetBeforeRecurrentList = (parmas: any) => {
    return httpAjax({
        url: `/queryRecordsName`,
        method: 'post',
        data: parmas,
    });
};
/**
 * 获取学科列表
 */
export const GetSubjectList = () => {
    return httpAjax({
        url: `/chart/paper/subject`,
        method: 'get',
    });
};
/**
 * 上传图片
 */
export const UploadPhoto = (params: any) => {
    return httpAjax({
        url: `/upload/photoToOss`,
        method: 'post',
        data: params
    });
};

/**
 * 新增
 */
export const AddRecurrent = (params: any) => {
    return httpAjax({
        url: `/chartRecordCopyToChartPaper`,
        method: 'post',
        data: params,
    });
};
/**
 * 删除
 */
export const DelRecurrent = (params: any) => {
    return httpAjax({
        url: `/hiddenChartPaper`,
        method: 'post',
        data: params,
    });
};